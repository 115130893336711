<script>
import { PurpleDocsUploadAdapterPlugin } from "@/services/CkImageUpload";
import { ClassicEditor } from "@ckeditor/ckeditor5-editor-classic";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import {
  Bold,
  Italic,
  Strikethrough,
  Underline,
} from "@ckeditor/ckeditor5-basic-styles";
import { AutoLink } from "@ckeditor/ckeditor5-link";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
import { Heading } from "@ckeditor/ckeditor5-heading";
import { Alignment } from "@ckeditor/ckeditor5-alignment";
import { List } from "@ckeditor/ckeditor5-list";
import { Table, TableToolbar } from "@ckeditor/ckeditor5-table";
import { Font } from "@ckeditor/ckeditor5-font";
import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
import { PasteFromOffice } from "@ckeditor/ckeditor5-paste-from-office";
import {
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
} from "@ckeditor/ckeditor5-image";

export default {
  name: "CkEditor",
  props: ["value"],
  data() {
    return {
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        plugins: [
          Heading,
          Essentials,
          Bold,
          Italic,
          Strikethrough,
          Underline,
          List,
          AutoLink,
          Paragraph,
          Alignment,
          Table,
          TableToolbar,
          Font,
          BlockQuote,
          Autoformat,
          PasteFromOffice,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageUpload,
          ImageResize,
          PurpleDocsUploadAdapterPlugin,
        ],
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "blockQuote",
            "bulletedList",
            "numberedList",
            "|",
            "alignment",
            "|",
            "insertTable",
            "imageUpload",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
        image: {
          toolbar: [
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "|",
            "imageResize",
            "|",
            "imageTextAlternative",
            "toggleImageCaption",
          ],
          resizeOptions: [
            {
              name: "resizeImage:original",
              value: null,
              icon: "original",
            },
            {
              name: "resizeImage:25",
              value: "25",
              icon: "small",
            },
            {
              name: "resizeImage:50",
              value: "50",
              icon: "medium",
            },
            {
              name: "resizeImage:75",
              value: "75",
              icon: "large",
            },
            {
              name: "resizeImage:100",
              value: "100",
              icon: "large",
            },
          ],
        },
      },
    };
  },
  watch: {
    value(newValue) {
      this.editorData = newValue;
    },
  },
};
</script>

<template>
  <ckeditor
    v-model="editorData"
    :config="editorConfig"
    :editor="editor"
    @input="$emit('input', editorData)"
  />
</template>

<style>
.ck-editor .ck-content {
  min-height: 50vh !important;
}

.ck-powered-by {
  display: none !important;
}
</style>
